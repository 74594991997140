import Api from 'Api/Api';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostFileQueryFulfilled, IPostFileQueryRejected } from 'store/actions/upload';
import { getEntityMethod } from '../../utils/entities';
import { Entity } from '../history';

export type IFileServiceResponse = AxiosResponse<IPostFileQueryFulfilled> &
  AxiosError<IPostFileQueryRejected>;

export interface IUploadFile {
  file: string;
  country: string;
  vendorId: string;
  requestTraceId: string;
  entity: Entity;
  version: string;
}

export interface IUploadFileMultipart {
  file: FormData;
  country: string;
  vendorId: string;
  requestTraceId: string;
  entity: Entity;
  version: string;
}

export interface IUploadAvailable {
  method: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  versions: string[];
}

export const uploadFileMultipart = async ({
  file,
  country,
  vendorId,
  requestTraceId,
  entity,
  version,
}: IUploadFileMultipart): Promise<IFileServiceResponse> => {
  let method = getEntityMethod(entity);
  let url = `/upload/v2/${entity}/${version}`;

  return Api[method]({
    url: url,
    body: file,
    config: {
      headers: {
        fileType: 'csv',
        country,
        requestTraceId,
        offset: 1,
        vendorId,
        'Content-Type': 'multipart/form-data',
      },
    },
  });
};
