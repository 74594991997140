export const MAXIMUM_FILE_SIZE = 1048000;
export const INVENTORY_ADMIN_PROJECT_NAME = 'bees-hub-inventory-admin';
export const INVENTORY_CSV_TEMPLATE_LINK =
  'https://beessitfilemgmt.blob.core.windows.net/files-tz/csv-storage_inventory-put-v2.csv?sig=u5piYyS3U%2BzQrxK0XCSAa6frMqQ6f1qP6LJX1xB1bdE%3D&se=3021-03-29T12%3A06%3A36Z&sv=2015-04-05&sp=r&sr=b';
export const PRODUCT_ASSORTMENT_INCLUSION_TEMPLATE_LINK =
  'https://beessitfilemgmt.blob.core.windows.net/files-tz/csv-storage_csv-storage-product-assortment-inclusion-post-v3.csv?sig=drRhK7LD2K7g2GbPEW0nRM3MZvDiIkpk4JG5jvptHuY%3D&se=3021-08-25T16%3A48%3A58Z&sv=2015-04-05&sp=r&sr=b';
export const TEMPLATE_VERSIONS = {
  V1: {
    INVENTORY:
      'https://beessitfilemgmt.blob.core.windows.net/files-tz/stoplight-docs_inventories.csv?sig=K58qgiG9HrFYYUC7kU%2FX4Ks9mxkCqYoQ7mpWtxRhi1A%3D&se=3020-10-20T19%3A39%3A56Z&sv=2015-04-05&sp=r&sr=b',
    ASSORTMENT:
      'https://beessitfilemgmt.blob.core.windows.net/files-tz/stoplight-docs_productsassortmentinclusion.csv?sig=PsMFnCRyc9DcwYWyG6i%2Fk0dOjIA%2FtHzyorcsbI8rW6o%3D&se=3020-10-20T19%3A39%3A58Z&sv=2015-04-05&sp=r&sr=b',
  },
  V2: {
    ASSORTMENT: '',
    INVENTORY:
      'https://beessitfilemgmt.blob.core.windows.net/files-tz/csv-storage_inventory-put-v2.csv?sig=u5piYyS3U%2BzQrxK0XCSAa6frMqQ6f1qP6LJX1xB1bdE%3D&se=3021-03-29T12%3A06%3A36Z&sv=2015-04-05&sp=r&sr=b',
  },
  V3: {
    INVENTORY: 'https://beessitfilemgmt.blob.core.windows.net/files-br/csv-storage_inventory-post-v3.csv?sig=88%2FZ701OCirSv84QLOMZ8Z6XoJli9tMY9iKMaKHJaWU%3D&se=3023-02-16T18%3A52%3A42Z&sv=2015-04-05&sp=r&sr=b',
    ASSORTMENT:
      'https://beessitfilemgmt.blob.core.windows.net/files-tz/csv-storage_csv-storage-product-assortment-inclusion-post-v3.csv?sig=drRhK7LD2K7g2GbPEW0nRM3MZvDiIkpk4JG5jvptHuY%3D&se=3021-08-25T16%3A48%3A58Z&sv=2015-04-05&sp=r&sr=b',
  },
};
