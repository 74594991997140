import { EU_API_COUNTRYS, HOST_URL_INGESTION, HOST_URL_INGESTION_EU } from "constants/api";

export const getApiHost = (country: any = ''): string => {

  let host = HOST_URL_INGESTION;

  if (EU_API_COUNTRYS.includes(country)) {
    host = HOST_URL_INGESTION_EU;
  }

  return host;

}

export default getApiHost;
