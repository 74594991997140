import { makeStyles } from '@material-ui/core';

export default makeStyles({
  '@global': {
    '[role="cell"]': {
      zIndex: 'unset',
    },
    '[role="tabpanel"]': {
      padding: 0,
      backgroundColor: 'transparent',
    },
    'h1, h2, h3, h4, h5, h6': {
      margin: 0,
    },
  },
});
