import React from 'react';
import { TogglesState } from 'store/reducers/toggles';

export function useFeatureToggles(): TogglesState {
  let isToggleOn = {};

  const isSomethingLoading = React.useMemo(() => [].some((item) => !!item), []);

  React.useEffect(() => {
    if (!isSomethingLoading) {
      isToggleOn = {};
    }
  }, [isSomethingLoading]);

  return { isToggleOn, isSomethingLoading };
}
