import { BaseQueryArg, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { generateRequestTraceId } from '../../utils/generators';

export interface DistributionCenterParams {
  country: string;
  vendorId: string;
}

export interface DistributionCenterResponse {
  id: string;
  name: string;
  externalId: string;
}

export interface DistributionCenterParsed {
  id: string;
  name: string;
  externalId: string;
}

export const getDistributionCentersQuery = ({
  country,
  vendorId,
}: DistributionCenterParams): BaseQueryArg<BaseQueryFn> => {
  return {
    url: `/distributionCenters?vendorId=${vendorId}`,
    method: 'GET',
    headers: {
      requestTraceId: generateRequestTraceId(),
      country: country,
      vendorId: vendorId,
    },
  };
};

export const getDistributionCentersTransformResponse = (
  response: DistributionCenterResponse[]
): DistributionCenterParsed[] => {
  return response.map((distributionCenter) => ({
    id: distributionCenter.id,
    name: distributionCenter.name,
    externalId: distributionCenter.externalId,
  }));
};
