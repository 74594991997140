import { combineReducers, configureStore, PreloadedState, Store } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import distributionCenters from 'store/reducers/distributionCenters';
import { rtkApi } from '../services';
import countriesReducer from './reducers/countries';
import historyReducer from './reducers/history';
import togglesReducer from './reducers/toggles';
import uploadReducer from './reducers/upload';

export const rootReducer = combineReducers({
  distributionCenters: distributionCenters,
  countries: countriesReducer,
  history: historyReducer,
  upload: uploadReducer,
  toggles: togglesReducer,
  [rtkApi.reducerPath]: rtkApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>): Store =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkApi.middleware),
  });

setupListeners(setupStore().dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
