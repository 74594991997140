import { formatMessage } from '../i18n/formatters';
import { Entity } from '../services/history';

const entitiesLabel: Record<Entity, string> = {
  [Entity.INVENTORY]: formatMessage({ id: 'HistoryTable.ENTITY_INVENTORY_VALUE' }),
  [Entity.PRODUCT_ASSORTMENT_INCLUSION]: formatMessage({
    id: 'HistoryTable.ENTITY_ASSORTMENT_VALUE',
  }),
};

export const getEntityMethod = (entity: Entity): 'put' | 'post' =>
  entity === Entity.INVENTORY ? 'put' : 'post';

export const getEntityLabel = (entity: Entity): string => entitiesLabel[entity] ?? '-';
