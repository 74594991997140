import { TEMPLATE_VERSIONS } from '../consts/configs';
import { Entity } from '../services/history';

export type VERSIONS = 'V1' | 'V2' | 'V3';

export const toText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result as string);

    reader.onerror = reject;
  });

export const toFormData = (file: File): FormData => {
  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

export const getCsvTemplateLink = (entity: Entity, version: VERSIONS): string => {
  let defaultFile = {
    [Entity.INVENTORY]: TEMPLATE_VERSIONS[version].INVENTORY,
    [Entity.PRODUCT_ASSORTMENT_INCLUSION]: TEMPLATE_VERSIONS[version].ASSORTMENT,
  }[entity];

  return defaultFile;
};
