import { LoadingBuzz } from '@admin-portal-shared-components/spinner';
import { useFeatureToggles } from 'hooks/useFeatureToggles';
import React, { lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { setToggles } from 'store/reducers/toggles';

const BulkEditorPage = lazy(() => import('./pages/BulkEditorPage/BulkEditorPage'));
const InventoryPage = lazy(() => import('./pages/InventoryPage/InventoryPage'));

const Router = (): JSX.Element => {
  const dispatch = useDispatch();
  const toggles = useFeatureToggles();

  React.useEffect(() => {
    dispatch(setToggles(toggles));
  }, [dispatch, toggles]);

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            flex: 1,
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingBuzz size="xlarge" />
        </div>
      }
    >
      <Routes>
        <Route path="/inventory-admin/bulk" element={<BulkEditorPage />} />
        <Route path="/inventory-admin" element={<InventoryPage />} />
        <Route path="/" element={null} />
      </Routes>
    </Suspense>
  );
};

export default Router;
