import { createSlice } from '@reduxjs/toolkit';
import { IPostFileQueryRejected, postFileQuery } from 'store/actions/upload';

const initialState = {
  requestTraceId: '',
  success: false,
  failure: false,
  loading: false,
  title: '',
  message: '',
  lines: [] as IPostFileQueryRejected['lines'],
  availableVersions: [],
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    resetToInitialState: () => initialState,
    setAvailableVersions: (state, { payload }) => {
      state.availableVersions = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postFileQuery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postFileQuery.fulfilled, (state, { payload }) => {
      state.requestTraceId = payload.requestTraceId;
      state.loading = false;
      state.success = true;
      state.title = 'File uploaded successfully!';
      state.message = `Your parent trace ID is: ${payload.requestTraceId}`;
    });
    builder.addCase(postFileQuery.rejected, (state, { payload }) => {
      state.loading = false;
      state.failure = true;
      state.title = (payload as IPostFileQueryRejected).title;
      state.message = (payload as IPostFileQueryRejected).message;
      state.lines = (payload as IPostFileQueryRejected)?.lines ?? [];
    });
  },
});

export default uploadSlice.reducer;
export const { resetToInitialState } = uploadSlice.actions;
