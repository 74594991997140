import { emptySplitApi } from './baseRTKApi';
import { getCountriesQuery, getCountriesTransformResponse } from './countries';
import {
  getDistributionCentersQuery,
  getDistributionCentersTransformResponse,
} from './distribution_center';
import { getHistoryQuery, getHistoryTransformResponse } from './history';
import { getInventoryQuery, getInventoryTransformResponse } from './inventory';

export const rtkApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getHistory: build.query({
      query: getHistoryQuery,
      transformResponse: getHistoryTransformResponse,
    }),
    getCountries: build.query({
      query: getCountriesQuery,
      transformResponse: getCountriesTransformResponse,
    }),
    getInventory: build.query({
      query: getInventoryQuery,
      transformResponse: getInventoryTransformResponse,
    }),
    getDistributionCenter: build.query({
      query: getDistributionCentersQuery,
      transformResponse: getDistributionCentersTransformResponse,
    }),
  }),
});

export const {
  useGetHistoryQuery,
  useGetCountriesQuery,
  useGetInventoryQuery,
  useGetDistributionCenterQuery,
} = rtkApi;
