import { BaseQueryArg, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { format, utcToZonedTime } from 'date-fns-tz';
import parseISO from 'date-fns/parseISO';
import { generateRequestTraceId } from '../../utils/generators';

export interface HistoryParams {
  page: number;
  country: string;
  entities?: Entity[];
}

export enum Entity {
  INVENTORY = 'inventory',
  PRODUCT_ASSORTMENT_INCLUSION = 'product_assortment_inclusion',
}

export interface IEntity {
  name: Entity;
}

interface HistoryLines {
  line: number;
  field: string;
  message: string;
}

export interface HistoryResponse {
  user: {
    name: string;
    email: string;
  };
  vendor: {
    name: string;
    id: string;
  };
  country: {
    name: string;
    code: string;
  };
  file: {
    lines: number;
    columns: number;
    delimiter: string;
  };
  status: {
    name: string;
    description: string;
    lines: HistoryLines[];
  };
  requestTraceId: string;
  createdAt: string;
  entity: IEntity;
}

export interface HistoryStatus {
  success: boolean;
  description: string;
  lines: HistoryLines[];
}
export interface HistoryParsed {
  pagination: {
    currentPage: number;
    total: number;
    showPageSizeSelector: boolean;
    showQuantityIndicator: boolean;
  };
  history: {
    user: string;
    vendor: string;
    country: string;
    lines: number | string;
    success: boolean;
    status: HistoryStatus;
    traceId: string;
    date: string;
    entity: IEntity;
  }[];
}

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatInTimeZone = (date: string): string =>
  format(utcToZonedTime(parseISO(date), tz), 'yyyy-MM-dd HH:mm:ss', { timeZone: tz });

export const getHistoryQuery = ({
  page,
  country,
  entities = [Entity.INVENTORY, Entity.PRODUCT_ASSORTMENT_INCLUSION],
}: HistoryParams): BaseQueryArg<BaseQueryFn> => ({
  url: `upload/history?entities=${entities.join()}&page=${page}&pageSize=25`,
  method: 'GET',
  headers: {
    requestTraceId: generateRequestTraceId(),
    country: country
  },
});

export const getHistoryTransformResponse = (
  response: HistoryResponse[],
  meta: { [key: string]: string }
): HistoryParsed => {
  const currentPage = Number(meta['pageable-current-page']);
  const total = Number(meta['pageable-total-elements']);
  const history = response.map((item) => ({
    user: item.user.name,
    vendor: item.vendor.name,
    country: item.country.name,
    lines: item?.file?.lines ?? '-',
    success: item.status.name === 'SUCCESS',
    status: {
      success: item.status.name === 'SUCCESS',
      description: item.status.description,
      lines: item.status.lines || [],
    },
    traceId: item.requestTraceId,
    date: formatInTimeZone(`${item.createdAt}Z`),
    entity: item.entity,
  }));

  return {
    history,
    pagination: {
      currentPage: currentPage + 1,
      total,
      showPageSizeSelector: false,
      showQuantityIndicator: true,
    },
  };
};
