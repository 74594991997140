import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';
import { StylesProvider } from '@material-ui/core';
import { createFeatureToggleServiceV2, useSidebar } from 'admin-portal-shared-services';
import { BulkEditorIcon, InventoryPageIcon } from 'components/sideBarIcons/SideBarIcons';
import { INVENTORY_ADMIN_PROJECT_NAME } from 'consts/configs';
import { GenerateId } from 'jss/src';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import useStyles from './App.styles';
import { AppbarConfig } from './components';
import { IntlProvider } from './i18n';
import { formatMessage } from './i18n/formatters';
import Router from './Router';
import { setupStore } from './store';

export interface EnvProps {
  optimizelyKey: string;
  name: string;
}

const generateId: GenerateId = (rule) => {
  return `inventory-admin--${rule.key}-${uuid().split('-', 1)}`;
};

export default function App({ optimizelyKey }: EnvProps): JSX.Element {
  useStyles();
  useSidebar({
    items: [
      {
        id: 'inventory-admin',
        title: formatMessage({ id: 'Sidemenu.INVENTORY_PAGE_LABEL' }),
        icon: InventoryPageIcon,
        path: '/inventory-admin',
      },
      {
        id: 'inventory-admin-bulk-operations',
        title: formatMessage({ id: 'Sidemenu.BULK_EDITOR_LABEL' }),
        icon: BulkEditorIcon,
        path: '/inventory-admin/bulk',
      },
    ],
    utils: [],
  });

  createFeatureToggleServiceV2(INVENTORY_ADMIN_PROJECT_NAME, optimizelyKey);

  return (
    <Provider store={setupStore()}>
      <StylesProvider generateClassName={generateId} injectFirst>
        <AppbarConfig>
          <IntlProvider>
            <BrowserRouter>
              <DesignTokensProvider>
                <Router />
              </DesignTokensProvider>
            </BrowserRouter>
          </IntlProvider>
        </AppbarConfig>
      </StylesProvider>
    </Provider>
  );
}
