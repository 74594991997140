import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rtkApi } from '../../services';
import { CountriesParsedResponse, Vendors } from '../../services/countries';

const countries: CountriesParsedResponse[] = [];
const vendors: Vendors[] = [];
const selectedCountry = '';
const selectedVendor = '';

const initialState = {
  countries,
  vendors,
  selectedCountry,
  selectedVendor,
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    selectCountry: (state, { payload }: PayloadAction<string>) => {
      state.selectedCountry = payload;
      const vendorsForSelectedCountry = (
        state.countries.find((item) => item.id === payload) as CountriesParsedResponse
      ).vendors;
      state.vendors = vendorsForSelectedCountry;
      state.selectedVendor = vendorsForSelectedCountry[0].id;
    },
    selectVendor: (state, { payload }: PayloadAction<string>) => {
      state.selectedVendor = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      rtkApi.endpoints.getCountries.matchFulfilled,
      (state, { payload }: PayloadAction<CountriesParsedResponse[]>) => {
        state.countries = payload;
        state.vendors = payload[0].vendors;
        state.selectedCountry = payload[0].id;
        state.selectedVendor = payload[0].vendors[0].id;
      }
    );
  },
});

export default countriesSlice.reducer;
export const { selectCountry, selectVendor } = countriesSlice.actions;
