import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { EU_API_COUNTRYS, HOST_API, HOST_API_EU } from 'constants/api';
import { initialAxios as axios } from '../Api/Api';

export const axiosBaseQuery =
  ({
    baseUrl,
  }: {
    baseUrl: string;
  }): BaseQueryFn<
    {
      url: string;
      method: Method;
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: Record<string, string>;
    },
    unknown,
    unknown
  > =>
    async ({ url, method, data, params, headers }) => {

      const country = headers?.country || '';
      let endpointUrl = baseUrl;

      const isEu = EU_API_COUNTRYS.includes(country);

      let host_prefix = HOST_API;
      if (isEu) {
        host_prefix = HOST_API_EU;
        endpointUrl = endpointUrl.replace('api/', 'api/eu/');
      }

      /* istanbul ignore else */
      if (url.includes('feedback-service/v1')) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        /* istanbul ignore else */
        if (timezone) {
          // eslint-disable-next-line no-param-reassign
          headers = { ...headers, timezone };
        }
      }

      if (url.includes('v1/admin-portal-shared-bff/user-metadata')) {
        return getResult('/api', url, method, data, params, headers);
      }

      if (url.includes('products') || url.includes('distributionCenters')) {
        return getResult(`${host_prefix}/taxonomy-file-service`, url, method, data, params, headers);
      }

      return getResult(endpointUrl, url, method, data, params, headers);
    };

const getResult = async (
  baseUrl: string,
  url: string,
  method: Method | undefined,
  data: unknown,
  params: unknown,
  headers: Record<string, string> | undefined
): Promise<
  | {
    data: never;
    meta: Record<string, string>;
  }
  | {
    error?: any;
    data?: undefined;
    meta?: undefined;
  }
> => {
  try {
    const result = await axios({ url: baseUrl + url, method, data, params, headers });
    return { data: result.data, meta: (result.headers as any) };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: (err.response?.data as string) || err.message,
      },
    };
  }
};

export const emptySplitApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '/api/data-ingestion-bff/' }),
  endpoints: () => ({}),
});

export { getResult };

