import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  Sidemenu: {
    BULK_EDITOR_LABEL: 'Bulk Editor',
    INVENTORY_PAGE_LABEL: 'Inventory',
  },
  BulkEditorPage: {
    TITLE: 'Bulk Editor',
    UPLOAD_TAB_TITLE: 'Uploads',
    ERROR_HEADER: 'Something went wrong!',
    ERROR_SUBHEADER:
      'Could not get countries and vendors. You can try again in a few minutes and it should be normalized',
    ERROR_INFO_TEXT: 'If the issue persists, you can report the problem.',
    ERROR_BUTTON_TEXT: 'Try again',
  },
  UploadsTab: {
    TITLE: 'History',
    ERROR_TITLE: 'Error in file upload',
  },
  HistoryTable: {
    STATUS_COLUMN: 'Status',
    STATUS_VALUE_SUCCESS: 'Success',
    STATUS_VALUE_FAILURE: 'Failed',
    DATE_COLUMN: 'Date',
    TRACE_ID_COLUMN: 'Trace ID',
    ERROR_DESCRIPTION_COLUMN: 'Error Description',
    ERROR_DESCRIPTION_VALUE: 'Details',
    TOTAL_ROWS_COLUMN: 'Total Rows',
    COUNTRY_COLUMN: 'Country',
    VENDOR_COLUMN: 'Vendor',
    USER_COLUMN: 'User',
    EMPTY_TITLE: 'No Uploads',
    EMPTY_MESSAGE: 'Your uploaded files will be displayed here.',
    COPIED_TOOLTIP: 'Copied!',
    ERROR_COPYING_TOOLTIP: 'Error copying :(',
    ENTITY_COLUMN: 'Entity',
    ENTITY_INVENTORY_VALUE: 'Inventory',
    ENTITY_ASSORTMENT_VALUE: 'Assortment',
  },
  InventoryTable: {
    TITLE_COLUMN: 'Product',
    QUANTITY_COLUMN: 'Inventory Quantity',
    EMPTY_TITLE: 'No Inventories',
    CURRENT_PAGE: 'Current Page',
    EMPTY_MESSAGE: 'Your Inventories data will be displayed here.',
  },
  UploadModal: {
    TITLE: 'Upload File',
    HEADER_TITLE: 'Upload File',
    MESSAGE: 'There is a limit of 2000 rows or 1MB per file.',
    SELECT_COUNTRY_LABEL: 'Country',
    SELECT_COUNTRY_PLACEHOLDER: 'Select a country',
    SELECT_VENDOR_LABEL: 'Vendor',
    SELECT_VENDOR_PLACEHOLDER: 'Select a vendor',
    DRAG_DROP_MESSAGE: 'Drag and drop the CSV file here or',
    DRAG_DROP_BUTTON: 'Select File',
    CANCEL_BUTTON: 'Cancel',
    UPLOAD_BUTTON: 'Upload',
    DOWNLOAD_TEMPLATE: 'Click here to download the CSV template for {entity}',
    Errors: {
      FILE_INVALID: 'The file is invalid.',
      FILE_EMPTY: 'The file is empty.',
      FILE_SIZE: 'There is a limit of 2000 rows per file.',
      FILE_SIZE_MAXIMUM: 'The maximum file size allowed is 1MB.',
      FILE_TYPE: 'Only CSV files are allowed.',
      UPLOAD_GENERIC_TITLE: 'Error in file upload.',
      UPLOAD_GENERIC_MESSAGE: 'Check bellow and upload the file again.',
    },
    ENTITY_SELECTOR: {
      LABEL: 'Entity',
      INVENTORY: 'Inventory',
      ASSORTMENT: 'Assortment',
    },
    UNAVAILABLE_VENDOR: "You don't have permission for this vendor",
  },
  Countries: {
    AR: 'Argentina',
    BB: 'Barbados',
    BO: 'Bolivia',
    BR: 'Brazil',
    CA: 'Canada',
    CL: 'Chile',
    CO: 'Colombia',
    DE: 'Germany',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    GB: 'Great Britain',
    KR: 'South Korea',
    SV: 'El Salvador',
    HN: 'Honduras',
    MX: 'Mexico',
    PE: 'Peru',
    PA: 'Panama',
    PY: 'Paraguay',
    PT: 'Portugal',
    ZA: 'South Africa',
    TZ: 'Tanzania',
    UG: 'Uganda',
    US: 'United States',
    UY: 'Uruguay',
    BE: 'Belgium',
    IC: 'Canary Islands',
    UK: 'United Kingdom',
    AQ: 'Antarctica',
    CZ: 'Czech Republic',
    NG: 'Nigeria',
    IN: 'India',
    ID: 'Indonesia',
    PH: 'Philippines',
    ES: 'Spain',
    NL: 'Netherlands',
  },
  InventoryPage: {
    TITLE: 'Inventory',
    ERROR_HEADER: 'Something went wrong!',
    ERROR_SUBHEADER:
      'Could not get countries and vendors. You can try again in a few minutes and it should be normalized',
    ERROR_INFO_TEXT: 'If the issue persists, you can report the problem.',
    ERROR_BUTTON_TEXT: 'Try again',
  },
};

export default enUS;
