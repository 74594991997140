import React from 'react';
import * as Grid from '@admin-portal-shared-components/grid';

export interface AppbarConfigProps {
  children: React.ReactNode;
}

export const AppbarConfig = ({ children }: AppbarConfigProps): JSX.Element => {
  return (
    <Grid.Container
      style={{
        rowGap: '1rem',
      }}
      type="fluid"
      sidebar
    >
      {children}
    </Grid.Container>
  );
};
