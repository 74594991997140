import { formatMessage } from '../i18n/formatters';

const requireFlagImage = (countryCode: string) => {
  return require(`../assets/flags/${countryCode.toLocaleLowerCase()}.svg`);
};

const PAD_COUNTRIES = {
  AR: {
    name: formatMessage({ id: 'Countries.AR' }),
    flag: requireFlagImage('AR'),
    locale: 'es_AR',
    languages: ['es-ar'],
  },
  BB: {
    name: formatMessage({ id: 'Countries.BB' }),
    flag: requireFlagImage('BB'),
    locale: 'en_BB',
    languages: ['en-bb'],
  },
  BO: {
    name: formatMessage({ id: 'Countries.BO' }),
    flag: requireFlagImage('BO'),
    locale: 'es_BO',
    languages: ['es-bo'],
  },
  BR: {
    name: formatMessage({ id: 'Countries.BR' }),
    flag: requireFlagImage('BR'),
    locale: 'pt_BR',
    languages: ['pt-br'],
  },
  CA: {
    name: formatMessage({ id: 'Countries.CA' }),
    flag: requireFlagImage('CA'),
    locale: 'en_CA',
    languages: ['en-ca', 'fr-ca'],
  },
  CL: {
    name: formatMessage({ id: 'Countries.CL' }),
    flag: requireFlagImage('CL'),
    locale: 'en_CL',
    languages: ['es-cl'],
  },
  CO: {
    name: formatMessage({ id: 'Countries.CO' }),
    flag: requireFlagImage('CO'),
    locale: 'es_CO',
    languages: ['es-co'],
  },
  DE: {
    name: formatMessage({ id: 'Countries.DE' }),
    flag: requireFlagImage('DE'),
    locale: 'de_DE',
    languages: ['en-de', 'de-de'],
  },
  DO: {
    name: formatMessage({ id: 'Countries.DO' }),
    flag: requireFlagImage('DO'),
    locale: 'es_DO',
    languages: ['es-do'],
  },
  EC: {
    name: formatMessage({ id: 'Countries.EC' }),
    flag: requireFlagImage('EC'),
    locale: 'es_EC',
    languages: ['es-ec'],
  },
  GB: {
    name: formatMessage({ id: 'Countries.GB' }),
    flag: requireFlagImage('GB'),
    locale: 'en_GB',
    languages: ['en-gb'],
  },
  KR: {
    name: formatMessage({ id: 'Countries.KR' }),
    flag: requireFlagImage('KR'),
    locale: 'ko_KR',
    languages: ['ko-kr'],
  },
  SV: {
    name: formatMessage({ id: 'Countries.SV' }),
    flag: requireFlagImage('SV'),
    locale: 'es_SV',
    languages: ['es-sv'],
  },
  HN: {
    name: formatMessage({ id: 'Countries.HN' }),
    flag: requireFlagImage('HN'),
    locale: 'es_HN',
    languages: ['es-hn'],
  },
  MX: {
    name: formatMessage({ id: 'Countries.MX' }),
    flag: requireFlagImage('MX'),
    locale: 'es_MX',
    languages: ['es-mx'],
  },
  PE: {
    name: formatMessage({ id: 'Countries.PE' }),
    flag: requireFlagImage('PE'),
    locale: 'es_PE',
    languages: ['es-pe'],
  },
  PA: {
    name: formatMessage({ id: 'Countries.PA' }),
    flag: requireFlagImage('PA'),
    locale: 'es_PA',
    languages: ['es-pa'],
  },
  PY: {
    name: formatMessage({ id: 'Countries.PY' }),
    flag: requireFlagImage('PY'),
    locale: 'es_PY',
    languages: ['es-py'],
  },
  PT: {
    name: formatMessage({ id: 'Countries.PT' }),
    flag: requireFlagImage('PT'),
    locale: 'pt_PT',
    languages: ['pt-pt'],
  },
  ZA: {
    name: formatMessage({ id: 'Countries.ZA' }),
    flag: requireFlagImage('ZA'),
    locale: 'en_GB',
    languages: ['en-gb'],
  },
  TZ: {
    name: formatMessage({ id: 'Countries.TZ' }),
    flag: requireFlagImage('TZ'),
    locale: 'en_TZ',
    languages: ['en-tz'],
  },
  UG: {
    name: formatMessage({ id: 'Countries.UG' }),
    flag: requireFlagImage('UG'),
    locale: 'en_UG',
    languages: ['en-ug', 'sw-ug', 'lg-ug'],
  },
  US: {
    name: formatMessage({ id: 'Countries.US' }),
    flag: requireFlagImage('US'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  UY: {
    name: formatMessage({ id: 'Countries.UY' }),
    flag: requireFlagImage('UY'),
    locale: 'en_UY',
    languages: ['en-uy'],
  },

  // EU Cluster insertion 

  BE: {
    name: formatMessage({ id: 'Countries.BE' }),
    flag: requireFlagImage('BE'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  IC: {
    name: formatMessage({ id: 'Countries.IC' }),
    flag: requireFlagImage('ID'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  UK: {
    name: formatMessage({ id: 'Countries.UK' }),
    flag: requireFlagImage('US'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  AQ: {
    name: formatMessage({ id: 'Countries.AQ' }),
    flag: requireFlagImage('AQ'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  CZ: {
    name: formatMessage({ id: 'Countries.CZ' }),
    flag: requireFlagImage('CZ'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  NG: {
    name: formatMessage({ id: 'Countries.NG' }),
    flag: requireFlagImage('NG'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  IN: {
    name: formatMessage({ id: 'Countries.IN' }),
    flag: requireFlagImage('IN'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  ID: {
    name: formatMessage({ id: 'Countries.ID' }),
    flag: requireFlagImage('ID'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  PH: {
    name: formatMessage({ id: 'Countries.PH' }),
    flag: requireFlagImage('PH'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  ES: {
    name: formatMessage({ id: 'Countries.ES' }),
    flag: requireFlagImage('ES'),
    locale: 'en_US',
    languages: ['en-us'],
  },
  NL: {
    name: formatMessage({ id: 'Countries.NL' }),
    flag: requireFlagImage('NL'),
    locale: 'en_US',
    languages: ['en-us'],
  },
};

export default PAD_COUNTRIES;
