import { BaseQueryArg, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { format, utcToZonedTime } from 'date-fns-tz';
import parseISO from 'date-fns/parseISO';
import { generateRequestTraceId } from '../../utils/generators';

export interface InventoryParams {
  page: number;
  size: number;
  orderBy: string;
  country: string;
  vendorId: string;
  distributionCenterId: string;
  vendorAccountId?: string;
}

export interface InventoryAvailable {
  vendorItemId: string;
  quantity: number;
  expirationDate?: string;
  daysToExpire?: string;
}

export interface InventoryLines {
  sku: string;
  productName: string;
  imageUrl: string;
  registeredOnPim: boolean;
  inventoryAvailable: InventoryAvailable;
}

export interface InventoryResponse {
  inventories: InventoryLines[];
  pagination: {
    page: number;
    hasMore: boolean;
  };
}

export interface InventoryParsed {
  sku: string;
  productName: string;
  imageUrl: string;
  onPim: boolean;
  inventoryAvailable: InventoryAvailableParsed;
}

export interface InventoryResponseParsed {
  inventories: {
    sku: string;
    productName: string;
    imageUrl: string;
    onPim: boolean;
    inventoryAvailable: InventoryAvailableParsed;
  }[];
  pagination: {
    page: number;
    hasMore: boolean;
  };
}

export interface InventoryAvailableParsed {
  quantity: number;
  expirationDate?: string | null;
  daysToExpire?: string;
}

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatInTimeZone = (date: string): string =>
  format(utcToZonedTime(parseISO(date), tz), 'yyyy-MM-dd HH:mm:ss', { timeZone: tz });

export const getInventoryQuery = ({
  page,
  size,
  orderBy,
  country,
  vendorId,
  distributionCenterId,
  vendorAccountId,
}: InventoryParams): BaseQueryArg<BaseQueryFn> => ({
  url: `/products?page=${page}&size=${size}&orderBy=${orderBy}`,
  method: 'GET',
  headers: {
    requestTraceId: generateRequestTraceId(),
    country: country,
    distributionCenterId: distributionCenterId,
    vendorAccountId: vendorAccountId,
    vendorId: vendorId,
  },
});

export const getInventoryTransformResponse = (
  response: InventoryResponse
): InventoryResponseParsed => {
  return {
    inventories: response.inventories.map((inventoryLine) => ({
      sku: inventoryLine.sku,
      productName: inventoryLine.productName,
      imageUrl: inventoryLine.imageUrl,
      onPim: inventoryLine.registeredOnPim,
      inventoryAvailable: {
        quantity: inventoryLine.inventoryAvailable.quantity,
        expirationDate:
          inventoryLine.inventoryAvailable.expirationDate != null
            ? formatInTimeZone(`${inventoryLine.inventoryAvailable.expirationDate}Z`)
            : null,
        daysToExpire: inventoryLine.inventoryAvailable.daysToExpire,
      },
    })),
    pagination: response.pagination,
  };
};
