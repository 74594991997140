import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rtkApi } from '../../services';
import { DistributionCenterParsed } from '../../services/distribution_center';

const distributionCenters: DistributionCenterParsed[] = [];
const selectedDistributionCenter = '';

const initialState = {
  distributionCenters,
  selectedDistributionCenter,
};

export const distributionCentersSlice = createSlice({
  name: 'distributionCenters',
  initialState,
  reducers: {
    selectDistributionCenter: (state, { payload }: PayloadAction<string>) => {
      state.selectedDistributionCenter = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      rtkApi.endpoints.getDistributionCenter.matchFulfilled,
      (state, { payload }: PayloadAction<DistributionCenterParsed[]>) => {
        state.distributionCenters = payload;
        state.selectedDistributionCenter = payload[0].id;
      }
    );
  },
});

export default distributionCentersSlice.reducer;
export const { selectDistributionCenter } = distributionCentersSlice.actions;
