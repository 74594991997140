import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TogglesState {
  isToggleOn: Record<string, boolean>;
  isSomethingLoading: boolean;
}

const initialState: TogglesState = {
  isToggleOn: {},
  isSomethingLoading: true,
};

export const togglesSlice = createSlice({
  name: 'toggles',
  initialState,
  reducers: {
    setToggles: (state, action: PayloadAction<TogglesState>) => {
      state.isToggleOn = action.payload.isToggleOn;
      state.isSomethingLoading = action.payload.isSomethingLoading;
    },
  },
});

export const { setToggles } = togglesSlice.actions;

export default togglesSlice.reducer;
