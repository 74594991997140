import { BaseQueryArg, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import PAD_COUNTRIES from 'consts/countries';
import { ICountry } from 'interfaces/ICountry';
import orderBy from 'lodash.orderby';

export interface Vendors {
  id: string;
  name: string;
}

export interface CountriesResponse {
  supportedCountries: ICountry['code'][];
  vendors: {
    id: string;
    displayName: string;
    country: string;
  }[];
}

export interface CountriesParsedResponse {
  id: string;
  name: string;
  flag: string;
  vendors: Vendors[];
}

const groupVendorByCountry = (metadata: CountriesResponse) => {
  const vendorsByCountry = [];
  const padSupportedCountries = Object.keys(PAD_COUNTRIES);
  for (const country of metadata.supportedCountries) {
    const vendors = metadata.vendors
      .filter((vendor) => vendor.country === country)
      .map((vendor) => ({
        id: vendor.id,
        name: vendor.displayName,
      }));
    if (vendors.length > 0) {
      vendorsByCountry.push({
        country,
        vendors,
      });
    }
  }

  return vendorsByCountry.filter((item) => padSupportedCountries.includes(item.country));
};

export const getCountriesQuery = (): BaseQueryArg<BaseQueryFn> => ({
  url: '/v1/admin-portal-shared-bff/user-metadata',
  method: 'GET',
});

export const getCountriesTransformResponse = (
  response: CountriesResponse
): CountriesParsedResponse[] => {
  const vendorsByCountry = groupVendorByCountry(response);
  return orderBy(
    vendorsByCountry.map((item) => ({
      id: item.country,
      name: PAD_COUNTRIES[item.country]?.name,
      flag: PAD_COUNTRIES[item.country]?.flag,
      vendors: [...item.vendors],
    })),
    ['name'],
    'asc'
  );
};
