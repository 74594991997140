import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatMessage } from 'i18n/formatters';
import { IFileServiceResponse, IUploadFileMultipart, uploadFileMultipart } from 'services/upload';
import { RootState } from 'store';
import { toFormData } from 'utils/csv';
import { generateRequestTraceId } from 'utils/generators';
import { Entity } from '../../services/history';

interface PostFile {
  file: File;
  country: string;
  vendorId: string;
  entity: Entity;
  version: string;
}

export interface IPostFileQueryFulfilled {
  requestTraceId: string;
}

export interface ILines {
  line: number;
  field: string;
  description: string;
}

interface OldRejectPayload {
  errorCode?: string;
  errorTitle?: string;
}

export interface IPostFileQueryRejected extends OldRejectPayload {
  title: string;
  message: string;
  code: string;
  detail?: string;
  lines?: ILines[];
}

export const postFileQuery = createAsyncThunk<
  IPostFileQueryFulfilled,
  PostFile,
  {
    state?: RootState;
    rejectValue?: IPostFileQueryRejected;
  }
>(
  'upload/postFileQuery',
  async ({ file: originalFile, country, vendorId, entity, version }, { rejectWithValue }) => {
    const requestTraceId = generateRequestTraceId();
    const file = toFormData(originalFile);

    const uploadParams = { vendorId, country, file, requestTraceId, entity, version };

    const response: IFileServiceResponse = await uploadFileMultipart(
      uploadParams as IUploadFileMultipart
    );

    if (response.hasError) {
      if (response.response) {
        const { errorCode, code, message, errorTitle, detail = '', lines } = response.response.data;
        const fileError = [code as string, errorCode as string].includes('DTB10')
          ? formatMessage({ id: 'UploadModal.Errors.UPLOAD_GENERIC_MESSAGE' })
          : detail;
        return rejectWithValue({
          title:
            errorTitle ||
            message ||
            formatMessage({ id: 'UploadModal.Errors.UPLOAD_GENERIC_TITLE' }),
          message: fileError,
          lines,
        });
      }
      return rejectWithValue({
        title: formatMessage({ id: 'UploadModal.Errors.UPLOAD_GENERIC_TITLE' }),
      });
    }
    return { ...response.data, requestTraceId };
  }
);
