import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatMessage } from 'i18n/formatters';

export interface IErrorDetails {
  lines: {
    line: number;
    field: string;
    message: string;
  }[];
  description: string;
  title: string;
}

const errorDetails: IErrorDetails = {
  lines: [],
  description: '',
  title: formatMessage({ id: 'UploadModal.Errors.UPLOAD_GENERIC_TITLE' }),
};

const openErrorDetails = false;

const initialState = {
  errorDetails,
  openErrorDetails,
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setErrorDetails: (state, { payload }: PayloadAction<IErrorDetails>) => {
      state.errorDetails.lines = payload.lines;
      state.errorDetails.description = payload.description;
      state.openErrorDetails = true;
    },
    closeErrorDetails: (state) => {
      state.errorDetails = errorDetails;
      state.openErrorDetails = false;
    },
  },
});

export default historySlice.reducer;
export const { setErrorDetails, closeErrorDetails } = historySlice.actions;
